// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-de-404-js": () => import("./../src/pages/de/404.js" /* webpackChunkName: "component---src-pages-de-404-js" */),
  "component---src-pages-en-404-js": () => import("./../src/pages/en/404.js" /* webpackChunkName: "component---src-pages-en-404-js" */),
  "component---src-templates-blog-item-page-js": () => import("./../src/templates/blogItemPage.js" /* webpackChunkName: "component---src-templates-blog-item-page-js" */),
  "component---src-templates-blog-page-js": () => import("./../src/templates/blogPage.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-conditions-js": () => import("./../src/templates/conditions.js" /* webpackChunkName: "component---src-templates-conditions-js" */),
  "component---src-templates-downloads-page-js": () => import("./../src/templates/downloadsPage.js" /* webpackChunkName: "component---src-templates-downloads-page-js" */),
  "component---src-templates-faq-page-js": () => import("./../src/templates/faqPage.js" /* webpackChunkName: "component---src-templates-faq-page-js" */),
  "component---src-templates-feature-js": () => import("./../src/templates/feature.js" /* webpackChunkName: "component---src-templates-feature-js" */),
  "component---src-templates-homepage-js": () => import("./../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-imprint-js": () => import("./../src/templates/imprint.js" /* webpackChunkName: "component---src-templates-imprint-js" */),
  "component---src-templates-landing-page-hub-js": () => import("./../src/templates/landingPageHub.js" /* webpackChunkName: "component---src-templates-landing-page-hub-js" */),
  "component---src-templates-landing-page-js": () => import("./../src/templates/landingPage.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-pricing-js": () => import("./../src/templates/pricing.js" /* webpackChunkName: "component---src-templates-pricing-js" */),
  "component---src-templates-privacy-policy-js": () => import("./../src/templates/privacy-policy.js" /* webpackChunkName: "component---src-templates-privacy-policy-js" */)
}

